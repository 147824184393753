@import '../../variables.scss';

.offer-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  :focus {
    outline: none;
  }

  .offer-modal-content {
    width: 1120px;
    height: 720px;
    display: flex;
    position: relative;
    background-color: $white;
    border: 1px solid $grey10;

    .offer-modal-content-image {
      width: 254px;
      height: 720px;
    }

    .offer-modal-content-close {
      position: absolute;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -1px;
      right: -1px;
      cursor: pointer;
      border: 1px solid $grey10;
    }

    .offer-modal-content-icon {
      position: absolute;
      top: 21px;
      right: 62px;
    }

    .offer-modal-content-container {
      padding: 33px 32px;
      width: 865px;

      .offer-modal-content-title {
        margin: 0 0 10px 0;
      }

      .offer-modal-content-sub-title {
        margin: 0;
      }

      .offer-modal-content-user-data {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;

        .offer-modal-content-user-data-item {
          width: 210px;
          margin-top: 20px;
        }

        .offer-modal-content-user-data-item-wide {
          width: 63%;
          margin-top: 20px;
        }
      }
      .offer-modal-content-text-area {
        width: 456px;
        height: 78px;
        resize: none;
        background-color: #ececec;
        margin-top: 6px;
        border: none;
        padding: 12px 22.5px;
      }
      .offer-modal-content-phone-row {
        display: flex;
        margin: 20px 0 15px 0;
        align-items: center;

        .offer-modal-content-phone-row-text-field {
          margin-left: 41px;
        }
      }
      .offer-modal-content-special-row {
        margin-top: 57px;
      }

      .offer-modal-content-statement {
        width: 773px;
        margin-top: 10px;
      }
    }

    .offer-modal-content-button-wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 33px;
      right: 32px;

      .offer-modal-content-button-wrapper-pdf {
        cursor: pointer;
        margin-right: 31px;
      }
    }

    .offer-modal-content-footer-text {
      width: 449px;
      margin-top: 29px;
    }
  }
}

.offer-modal-success-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .offer-modal-success-content-title {
    margin: 0;
    width: 597px;
    text-align: center;
  }

  .offer-modal-success-content-subtitle {
    margin: 30px 0;
  }
}
