@import '../../../../variables.scss';
.exploitation {
  .section1 {
    margin: 0;
    display: flex;
    width: 100%;
    min-width: 1184px;
    justify-content: center;
    position: relative;
    img {
      width: 1184px;
      height: 510px;
    }
    h1 {
      color: white;
      position: absolute;
      top: 64px;
      margin: 0;
      font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: 54px;
      line-height: 59.4px;
      letter-spacing: 1px;
    }
    span {
      color: white;
      position: absolute;
      top: 148px;
      width: 724px;
      font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
}
