@import '../../variables.scss';

.priorities-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  :focus {
    outline: none;
  }

  .priorities-modal-content {
    width: 1120px;
    height: 720px;
    display: flex;
    position: relative;
    background-color: $white;
    border: 1px solid $grey10;

    .priorities-modal-content-image {
      width: 254px;
      height: 720px;
    }

    .priorities-modal-content-close {
      position: absolute;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -1px;
      right: -1px;
      cursor: pointer;
      border: 1px solid $grey10;
    }

    .priorities-modal-content-icon {
      position: absolute;
      top: 21px;
      right: 62px;
    }

    .priorities-modal-content-container {
      padding: 33px 32px;
      width: 865px;

      .priorities-modal-content-title {
        margin: 0;
      }

      .priorities-modal-content-sub-title {
        margin: 3px 0 41px 0;
      }

      .priorities-modal-content-text-container {
        display: flex;
        flex-wrap: wrap;
        height: 480px;
        align-items: flex-start;
        justify-content: space-between;

        .priorities-modal-content-text-container-item {
          width: 380px;

          .priorities-modal-content-question {
            margin-bottom: 5px;
          }

          .priorities-modal-content-statement {
            white-space: pre-line;
          }
        }
      }
    }

    .priorities-modal-content-button-wrapper {
      position: absolute;
      bottom: 33px;
      right: 32px;
    }
  }
}
