@import "../../variables.scss";

.global-link {
  margin-top: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  .global-link-icon {
    margin-right: 8px;
  }
}
