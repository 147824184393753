@import '../../../../variables.scss';
.exploitation {
  .section2 {
    display: grid;
    grid-template-columns: 560px 560px;
    grid-template-rows: 550px 550px;
    background: #e6f4f6;
    .section2-cell {
      display: flex;
      flex-direction: column;
      margin: 30px;
      span.title {
        font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $navy-grey;
      }
      h2.title {
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: 1px;
        color: $navy-grey;
      }
      ul.list {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        padding-left: 32px;
        li {
          padding-bottom: 25px;
        }
      }
      &:nth-child(2) {
        margin: 0;
        background-image: url('../../../../assets/images/exploitation/sections/section2/grid1_1.png');
      }
      &:nth-child(3) {
        margin: 0;
        background-image: url('../../../../assets/images/exploitation/sections/section2/grid1_2.png');
      }
    }
  }
}
