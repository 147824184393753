@import './variables.scss';

@font-face {
  font-family: 'AllianzNeo-Bold';
  src: url('./assets/fonts/AllianzNeo-Bold.ttf');
}

@font-face {
  font-family: 'AllianzNeo-BoldItalic';
  src: url('./assets/fonts/AllianzNeo-BoldItalic.ttf');
}

@font-face {
  font-family: 'AllianzNeo-Condensed';
  src: url('./assets/fonts/AllianzNeo-Condensed.ttf');
}

@font-face {
  font-family: 'AllianzNeo-CondensedBold';
  src: url('./assets/fonts/AllianzNeo-CondensedBold.ttf');
}

@font-face {
  font-family: 'AllianzNeo-CondensedLight';
  src: url('./assets/fonts/AllianzNeo-CondensedLight.ttf');
}

@font-face {
  font-family: 'AllianzNeo-Italic';
  src: url('./assets/fonts/AllianzNeo-Italic.ttf');
}

@font-face {
  font-family: 'AllianzNeo-Light';
  src: url('./assets/fonts/AllianzNeo-Light.ttf');
}

@font-face {
  font-family: 'AllianzNeo-LightItalic';
  src: url('./assets/fonts/AllianzNeo-LightItalic.ttf');
}

@font-face {
  font-family: 'AllianzNeo-Regular';
  src: url('./assets/fonts/AllianzNeo-Regular.ttf');
}

@font-face {
  font-family: 'AllianzNeo-SemiBold';
  src: url('./assets/fonts/AllianzNeo-SemiBold.ttf');
}

@font-face {
  font-family: 'AllianzNeo-SemiBoldItalic';
  src: url('./assets/fonts/AllianzNeo-SemiBoldItalic.ttf');
}

.primary-button-text {
  font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-small {
  font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: $white;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 15px;
}

.button-small-dark {
  @extend .button-small;
  color: $navy-grey;
}

.matrix-text {
  font-family: 'AllianzNeo-SemiBold', Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: $navy-grey;
  line-height: 18px;
}

.matrix-text-bold {
  font-size: 14px;
  line-height: 18px;
  font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
  text-decoration: underline;
}

.navigation-link-text {
  font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 1%;
}

.h1-bold {
  font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
  font-size: 54px;
  letter-spacing: 1px;
  color: $navy-grey;
  line-height: 59px;
}

.paragraph {
  font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: $navy-grey;
  line-height: 24px;
}

.bold {
  font-weight: bold;
}

.paragraph-grey {
  @extend .paragraph;
  color: $inactive-links;
}

.paragraph-highlight {
  @extend .paragraph;
  font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
}

.paragraph-highlight-blue {
  @extend .paragraph-highlight;
  color: $primary-blue;
}

.paragraph-blue {
  @extend .paragraph;
  color: $primary-blue;
}

.paragraph-small {
  @extend .paragraph;
  font-size: 14px;
  line-height: 18px;
}

.paragraph-small-highlight {
  @extend .paragraph-small;
  font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
}

.h3-dark-blue-bold-caps {
  font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  color: $secondary-dark-blue;
}

.h3-dark-grey-bold-caps {
  @extend .h3-dark-blue-bold-caps;
  color: $inactive-links;
}

.navigation-link {
  font-size: 16px;
  letter-spacing: 1px;
  font-family: 'AllianzNeo-SemiBold', Arial, Helvetica, sans-serif;
  color: $primary-blue;
}

.navigation-link-inactive {
  @extend .navigation-link;
  color: $inactive-links;
}

.h1-small {
  font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
  font-size: 36px;
  color: $navy-grey;
  line-height: 47px;
}

.h2-headline {
  font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: $rich-petrol;
  line-height: 31px;
  font-weight: normal;
}

.h2-headline-grey {
  @extend .h2-headline;
  color: $navy-grey;
}

.image-description {
  font-family: 'AllianzNeo-SemiBold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: $shadow;
  line-height: 23px;
}

.text-link {
  font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: $primary-blue;
  line-height: 21px;
  text-align: center;
}

.text-area-placeholder {
  font-size: 16px;
  font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
  color: $navy-grey;

  ::placeholder {
    font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: $grey11;
  }
}

.offer-headline-blue {
  font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 26px */
  color: $primary-blue;
}

.table-content-small-light {
  font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
  color: $white;
  font-size: 16px;
  line-height: 18px;
}

.table-content-small-dark {
  font-family: 'AllianzNeo-SemiBold', Arial, Helvetica, sans-serif;
  color: $navy-grey;
  font-size: 16px;
  line-height: 18px;
}

.error-message-text-only {
  font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
  color: $white;
  font-size: 14px;
  line-height: 18px;
}

body {
  margin: 0;
  font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.App {
  width: 100%;
  max-width: 1120px;
}

.questionnaire-app {
  width: 1121px;
  height: 676px;
  position: relative;
  margin: 0;
}

a {
  color: inherit;
}

.logo {
  position: absolute;
  right: 0;
  top: 50px;
}

.copyright {
  position: absolute;
  left: 50px;
  bottom: 75px;
}

.black-text {
  color: $black;
  font-size: 18px;
}

.small-black-text {
  color: $black;
  font-size: 16px;
}

.blue-text {
  color: $dark-blue;
  font-weight: bold;
  font-size: 18px;
}

.big-container {
  height: 566px;
}

.small-container {
  height: 441px;
}

.form-row {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px;

  .form-row-label {
    font-weight: bold;
  }

  > select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid $black;
    background-color: $white;
    padding: 11px 8px;
    width: 260px;
    position: relative;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
  }
  > input {
    border: 1px solid $black;
    background-color: $white;
    padding: 11px 8px;
    width: 242px;
  }
}

.with-left-margin {
  margin-left: 50px;
}

.static-page {
  display: flex;
  flex-direction: column;
  font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
  min-height: 840px;

  &-title {
    color: $navy-grey;
    font-size: 54px;
    font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 59px;
    padding-left: 32px;
  }
  &-info {
    color: #2f2f2f;
    font-size: 16px;
    max-width: 54.95vw;
    margin-top: 30px;
    padding-left: 32px;
    line-height: 24px;
  }
}

.info-btn {
  color: $white;
  width: 21px;
  height: 21px;
  background: $tooltip-grey;
  border: 2px solid $border-grey;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  box-sizing: border-box;
  position: absolute;
  right: -30px;
}
