@import '../../../../variables.scss';
.exploitation {
  .section5 {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background: #feefe5;
    margin: 0;
    padding: 0 32px 60px 32px;
    .columns {
      display: flex;
      flex-direction: row;
      margin-top: 60px;
      height: 300px;
      width: 100%;
      height: 454px;
      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        border-right: 1px solid #c2c2c2;
        color: $navy-grey;
        font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        img.icon {
          width: 80px;
          height: 80px;
        }
        h4.title {
          margin-top: 25px;
          font-family: 'AllianzNeo-SemiBold', Arial, Helvetica, sans-serif;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
        }
        span.content {
          padding: 0 16px;
          white-space: pre-line;
        }
        ul.list {
          margin-top: 30px;
          padding-left: 40px;
          align-self: baseline;
        }
        &:last-of-type {
          border: none;
        }
      }
    }
    .support-offers {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 340px;
      margin-top: 96px;
      color: $navy-grey;
      .col {
        display: flex;
        flex-direction: column;
        width: calc(100% - 560px);
        span.title {
          font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
        }
        h2.title {
          font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 26px;
          line-height: 110%;
          letter-spacing: 1px;
          margin-bottom: 5px;
        }
        ul.list {
          font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
        }
        &:last-of-type {
          align-items: flex-end;
          img {
            height: 338px;
            width: 511px;
          }
          width: 560px;
        }
      }
    }
    .team-support {
      display: flex;
      flex-direction: column;
      margin-top: 96px;
      width: 100%;
      padding-bottom: 370px;
      background: #feefe5;
      position: relative;
      display: none; //temporary
      span.title {
        font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
      }
      h2.title {
        font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: 1px;
        margin-bottom: 64px;
      }

      .card-journey-wrapper {
        display: flex;
        width: 717px;
        height: 424px;
        background: #feefe5;
        justify-content: left;
        flex-direction: row-reverse;
        position: relative;
        margin-bottom: 62px;
        z-index: 1;
        .number-block {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 119px;
          height: 80px;
          align-self: center;
          position: relative;
          background: #efe3da;
          font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 54px;
          line-height: 110%;
          letter-spacing: 1px;
        }
        .card-journey {
          display: flex;
          flex-direction: row;
          position: relative;
          width: 630px;
          height: 424px;
          background: #efe3da;
          margin-left: -31px;
          color: $navy-grey;
          box-sizing: border-box;
          padding: 27px 47px 27px 27px;
          font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
          .col {
            width: 266px;
            h4.title {
              color: $primary-blue;
              font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 150%;
            }
            h4.sub-title {
              font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
              font-style: normal;
              font-size: 16px;
              font-weight: 700;
              line-height: 150%;
              &:last-of-type {
                margin-bottom: 0;
              }
            }
            ul.list {
              padding-left: 25px;
              margin-top: 0;
            }
            a.global-link {
              position: absolute;
              bottom: 32px;
            }
          }
          img.journey-image {
            position: relative;
            width: 266px;
            height: 373px;
            left: 25px;
            z-index: 10;
          }
        }
      }
      .card-journey-wrapper-right {
        @extend .card-journey-wrapper;
        flex-direction: row;
        left: 432px;
        .number-block {
          left: -27px;
        }
        .card-journey {
          right: -3px;
          width: 650px;
        }
      }
      .arrow-team-support {
        display: flex;
        position: absolute;
        top: 140px;
        justify-content: center;
        width: 100%;
        img {
          width: 356px;
        }
      }
    }
  }
}
