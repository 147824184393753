@import '../Profile/Profile';
@import '../../global.scss';
@import '../../variables.scss';

.MuiPaper-root {
  color: red;
}
.welcome {
  @extend .profile;
  .welcome-image {
    @extend .profile-image;
  }
  .welcome-container {
    @extend .profile-container;
    form {
      width: 100%;
    }
    .confirm-button {
      margin-top: 48px;
    }
  }
  .welcome-title {
    @extend .profile-title, .h1-bold;
  }
  .welcome-form {
    @extend .profile-form;
    width: 100% !important;
    margin-top: 0px !important;
    .field {
      width: 100%;
      margin-bottom: 10px;
    }
    .label {
      font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 25px;
      color: $navy-grey;
    }
    .profile-form-selects-wrapper {
      flex-wrap: wrap;
    }
  }
}
