@import '../../variables.scss';

.journey-recommendations-container {
  .journey-recommendations-container-title {
    margin: 0 auto;
    width: 544px;
  }
  .journey-recommendations-container-title-h1 {
    width: 640px;
  }
  .paragraph-on-title {
    max-width: 640px;
  }
  .h3-dark-blue-bold-caps {
    text-transform: none;
    color: $primary-blue;
    margin-bottom: 8px;
  }
  .images-wrapper {
    .image-card {
      .info-block {
        border-bottom: 1px solid $grey6;
      }
    }
    .paragraph {
      ul {
        padding-left: 16px;
        margin-top: 0;
      }
      li {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .paragraph {
    margin-bottom: 29px;
  }

  .image-card {
    .image {
      width: 352px;
      height: 200px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .image-card-footer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .icons-block {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .icon-wrap {
        display: flex;
        justify-content: center;
        margin-right: 18px;
      }
      .icon-wrap img {
        height: 18px;
        width: 18px;
        margin-right: 8px;
      }
      .icon-text {
        font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 18px;
        color: $navy-grey;
      }
    }
    .btn-block {
      margin-top: 30px;
      button {
        margin-bottom: 12px;
        width: 100%;
      }
    }
  }
  .journey-recommendations-all {
    margin-top: 60px;
    margin-bottom: 82px;

    .images-wrapper .image-card .info-block {
      display: flex;
      height: auto;
      border: none;
    }
    .text-block {
      height: 200px;
      max-width: 352px;
      border-bottom: 1px solid $grey6;
    }
    &-title {
      padding-bottom: 20px;
      margin-right: 72px;
      border-bottom: 1px solid $border-grey;
      margin-bottom: -28px;
    }
    .images-wrapper {
      margin-top: auto;
      .image {
        height: 200px;
        width: 160px;
        margin-right: 20px;
      }
    }
    .icons-block {
      margin-left: 192px;
    }
    .image-card-footer .btn-block .button:first-child {
      max-width: 200px;
    }
    .image-card-footer .btn-block .button:last-child {
      max-width: 287px;
      margin-left: 24px;
    }
  }
  .image-card.priority-card {
    max-width: 352px;
    margin-right: 16px;
    margin-bottom: 60px;
  }
}

.themself-recomendations {
  .paragraph.paragraph-on-title {
    margin-bottom: -50px;
  }
  .journey-recommendations-all {
    margin-top: auto;
  }
  .journey-recommendations-all
    .image-card-footer
    .btn-block
    .button:last-child {
    margin-left: 192px;
    max-width: 200px;
  }
}
