@import '../../variables.scss';

.global-header {
  z-index: 2;
  background-color: $secondary-dark-blue;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .global-header-wrapper {
    width: 100%;
    height: 80px;
    min-height: 80px;
    max-width: 1184px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .global-header-logo {
      cursor: pointer;
      margin-left: 32px;
      width: 130px;
    }

    .global-header-title {
      font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.01em;
      color: $white;
    }
    .button-white-outline {
      &:last-of-type {
        margin-right: 32px;
      }
    }
  }
}
