@import '../../variables.scss';

.button {
  padding: 15px 23px;
  border-radius: 4px;
  height: 50px;
  letter-spacing: 1px;
  cursor: pointer;
  white-space: pre;

  &-inactive {
    cursor: default;
  }

  &-blue {
    &-outline {
      background-color: $white;
      border: 2px solid $primary-blue;
      color: $primary-blue;

      &:hover {
        color: $white;
        background-color: $primary-dark-blue;
        border: 2px solid $primary-dark-blue;
      }
    }

    &-default {
      background-color: $primary-blue;
      border: 2px solid $primary-blue;
      color: $white;

      &:hover {
        background-color: $primary-dark-blue;
        border: 2px solid $primary-dark-blue;
      }
    }
  }

  &-white {
    &-outline {
      background-color: transparent;
      color: $white;
      border: $white 2px solid;
    }
  }

  &-orange {
    &-outline {
      background-color: $white;
      border: 2px solid $primary-orange;
      color: $primary-orange;

      &:hover {
        background-color: $primary-dark-orange;
        border: 2px solid $primary-dark-orange;
        color: $white;
      }
    }

    &-default {
      background-color: $primary-orange;
      border: 2px solid $primary-orange;
      color: $white;

      &:hover {
        background-color: $primary-dark-orange;
        border: 2px solid $primary-dark-orange;
      }
    }
  }
  &-blue-grey-default {
    background-color: $rich-petrol;
    border: 2px solid $rich-petrol;
    color: $white;
    &:hover {
      background-color: $rich-petrol;
      border: 2px solid $rich-petrol;
    }
  }
  &-white-grey-default {
    background-color: $white;
    border: 2px solid $dark-grey;
    color: $dark-grey;
    &:hover {
      background-color: $white;
      border: 2px solid $dark-grey;
    }
  }
  &-green-grey-default {
    background-color: $green-active;
    border: 2px solid $green-active;
    color: $white;
    &:hover {
      background-color: $green-active;
      border: 2px solid $green-active;
    }
  }
}
