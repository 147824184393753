.exploitation-container {
  .h1-bold {
    max-width: 593px;
  }
  .journey-recommendations-container {
    .paragraph {
      max-width: 576px;
    }
  }
}
