@import '../../variables.scss';

$arrow-color: #c2c2c2;
$arrow-size: 1em;
$arrow-border: 0.2em;

.static-page-info.paragraph {
  padding-left: 0px;
}

.shift-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 88px;

  &-center-block {
    display: flex;
    justify-content: space-between;
  }

  .static-page-title {
    padding-left: 0px;
  }

  .line-arrow-block {
    margin: 20px 20px 20px 22px;
    height: 214px;
    width: 215px;
    position: relative;
    background: url('../../assets/svg/shift-page-graph.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .vector {
    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      transition: all 0.5s ease-in-out;
    }
    &:before {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid $arrow-color;
      z-index: 1;
    }
  }
  .vector.vector-right {
    width: 33.78vw;

    &:before {
      top: 50%;
      right: -1px;
      transform: translate(0, -0.35em);
    }

    &:after {
      top: 50%;
      width: 100%;
      height: 2px;
      transform: translate(0, -50%);
      background-color: $arrow-color;
      position: absolute;
    }
  }
  .vector.vector-top {
    &:before {
      left: 50%;
      transform: translate(-50%, -0.4em) rotate(-90deg);
    }
    &:after {
      left: 50%;
      top: 50%;
      width: 2px;
      height: 100%;
      transform: translate(-50%, -50%);
      background-color: $arrow-color;
      position: absolute;
    }
  }

  .circle {
    position: absolute;
    height: 122.58px;
    width: 122.58px;
    margin: 0 auto;
    vertical-align: middle;
    text-align: center;
    line-height: 122px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 13px;
    color: $navy-grey;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .circle.circle-left {
    background-color: $greyC4;
    top: 57%;
    left: 42%;
    overflow: hidden;
    border: 1px solid $white;
  }
  .circle.circle-right {
    background-color: $blue-sea;
    top: 40%;
    left: 61%;
  }
  .elipse {
    border-bottom-left-radius: 245.16px;
    border-top-left-radius: 245.16px;
    right: -111%;
    position: absolute;
    border: 1px solid $white;
    background: #4b859b;
    transform: translate(-50%, -50%);
    top: 6%;
  }
}
.shift-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 260px;
  &-title {
    display: flex;
    align-items: center;
    position: relative;
  }
  &-subtitle {
    margin: 0;
  }
  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $navy-grey;
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
  }
}
#shift-page-peronally-tooltip,
#shift-page-dinamic-tooltip {
  .MuiTooltip-tooltip {
    color: $white;
    background: $tooltip-grey !important;
    margin-left: 8px;
    max-width: 370px;
    font-size: 16px !important;
    padding: 25px 18px 19px 24px !important;
    line-height: 20px !important;
    border: 1px solid $greyC4;
    border-radius: 2px;
    box-shadow: 0 0 6px $shadow;
    max-width: 400px !important;
  }
  .MuiTooltip-arrow:after,
  .MuiTooltip-arrow:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
  .MuiTooltip-arrow:before {
    position: absolute;
    left: 1px;
    width: 0;
    height: 0;
    border-style: solid;
    top: 1px;
    border-color: transparent transparent $tooltip-grey transparent !important;
    border-width: 0 1em 1em 1em;
  }
  .MuiTooltip-arrow:after {
    border-color: transparent transparent $greyC4 transparent;
    border-width: 0 1.2em 1em 1.2em;
  }
}
