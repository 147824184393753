@import '../../../../variables.scss';
.exploitation {
  .section4 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: #dfeff2;
    height: 802px;
    margin: 0;
    padding: 127px 260px 0 260px;
    .row {
      display: flex;
      flex-direction: row;
      gap: 32px;
    }
    .card {
      display: flex;
      height: 270px;
      width: 200px;
      flex-direction: column;
      align-items: center;
      border-radius: 16px;
      border: 2px solid #007ab3;
      color: $navy-grey;
      img {
        position: relative;
        top: -60px;
        width: 80px;
        height: 80px;
      }
      h4.title {
        width: 180px;
        font-family: 'AllianzNeo-SemiBold', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        position: relative;
        top: -85px;
        margin: 0;
        padding-top: 40px;
      }
      span.content {
        padding: 10px;
        font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        position: relative;
        top: -70px;
      }
    }
  }
}
