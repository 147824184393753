@import '../../variables.scss';

.global-nav-bar {
  .global-nav-bar-container {
    width: 600px;
    height: 21px;

    margin: 30px 0 37px 0px;
    font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
    a {
      text-decoration: none;
    }

    .global-nav-bar-main-link {
      color: $primary-grey;
    }

    .global-nav-bar-chevron {
      margin: 0 10px;
    }

    .global-nav-bar-current {
      color: $primary-blue;
    }
  }
}
