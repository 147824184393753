.login-page {
  position: relative;
  .alert {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    min-width: 200px;
  }
}
