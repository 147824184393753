@import '../../variables.scss';

.zit-modal
  .zit-modal-content
  .zit-modal-content-container
  .zit-modal-content-title {
  position: absolute;
  width: 865px;
  height: 31px;
  left: 255px;
  top: 45px;
  margin: 0;
  font-family: 'AllianzNeo-Regular', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}
.zuruck {
  width: 52px;
  height: 21px;
  padding: 0;
  position: absolute;
  right: 33px;
  bottom: 34px;
  color: $primary-blue;
  font-family: 'AllianzNeo-SemiBold', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  background: none;
  cursor: pointer;
}
