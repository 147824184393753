@import '../../variables.scss';

.priorities {
  min-height: 1020px;
  .priorities-top-wrapper {
    display: flex;
    justify-content: space-between;

    .priorities-top-wrapper-left {
      width: 544px;
    }

    .priorities-top-wrapper-right {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-end;
      height: 112px;

      .priorities-top-wrapper-small-text {
        text-align: right;
        width: 352px;
      }
    }
  }

  .priorities-title {
    margin-bottom: 5px;
  }

  .priorities-hr {
    margin: 0 0 28px 0;
    border: none;
    height: 1px;
    color: $grey6;
    background-color: $grey6;
  }

  .priorities-items-container {
    display: flex;
    margin-bottom: 36px;
    justify-content: space-between;

    .priorities-items-container-item {
      width: 256px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
