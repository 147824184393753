@import '../../variables.scss';
.wrapper-exploitation {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  left: 0;
}
.exploitation {
  & > * {
    margin-left: 32px;
    margin-right: 32px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 85px;
  width: 1184px;
  .subtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      margin: 64px 0 0 0;
      font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 110%;
      text-align: center;
      letter-spacing: 1px;
    }
    span {
      padding: 20px 0 40px 0;
      font-family: 'AllianzNeo-Light', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 110%;
      text-align: center;
      letter-spacing: 1px;
      text-align: center;
    }
  }
}
