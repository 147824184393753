@import '../../../../variables.scss';
.exploitation {
  .section3 {
    display: grid;
    grid-template-columns: 560px 560px;
    grid-template-rows: 435px 435px;
    background: #f8f4f2;
    color: $navy-grey;
    .section3-cell {
      display: flex;
      flex-direction: column;
      margin: 30px;
      span.title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
      }
      h2.title {
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: 1px;
      }
      span.sub-title {
        font-family: 'AllianzNeo-Bold', Arial, Helvetica, sans-serif;
        font-size: 16px;
        line-height: 150%;
        font-weight: 700;
        margin: 5px 0 25px 0;
      }
      ul.list {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        padding-left: 32px;
        li {
          padding-bottom: 25px;
        }
      }
      &:nth-child(1) {
        margin: 0;
        background-image: url('../../../../assets/images/exploitation/sections/section3/grid2_1.png');
      }
      &:nth-child(4) {
        margin: 0;
        background-image: url('../../../../assets/images/exploitation/sections/section3/grid2_2.png');
      }
    }
  }
}
