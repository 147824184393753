$black: #000000;

$white: #ffffff;

$light-blue: #c9eaf9;
$blue: #c8d0db;
$dark-blue: #4e6489;
$primary-blue: #007ab3;
$lighten-primary-blue: #b2d6e8;
$primary-dark-blue: #006192;
$secondary-dark-blue: #003781;
$material-ui-blue: #476489;
$navy-blue: #003781;
$table-content: rgba(0, 122, 179, 0.1);
$blue-sea: #5bcbf5;
$light-result-blue: #94a0b8;
$medium-result-blue: #6c7f9f;
$dark-result-blue: #476489;

$light-grey: #eee8e7;
$background-grey: #e5e5e5;
$dark-grey: #5a5a5a;
$primary-grey: #646363;
$background-grey: #e5e5e5;
$grey2: #5d5d5d;
$grey6: #d9d9d9;
$grey7: #b0b0b0;
$grey8: #ececec;
$grey10: #cecece;
$grey11: #9b9b9b;
$grey12: #dadada;
$grey13: #cccccc;
$grey14: #ebebeb;
$message-grey: #5e5e5e;
$greyC4: #c4c4c4;
$navy-grey: #414141;
$tooltip-grey: #5a5360;
$border-grey: #d9d9d9;
$inactive-links: #999999;

$light-orange: #f8d4bc;
$orange: #f1985d;
$orange2: #ff934f;
$bright-orange: #fb6100;
$primary-orange: #f86200;
$primary-dark-orange: #e15200;

$yellow: #f7d370;
$dark-yellow: #fcba07;

$rich-petrol: #007d8c;

$beige: #ffd4b9;

$shadow: #2f2f2f;

$purple: #8a669c;

$red: #ba1d33;

$bright-green: #1d6c2e;
$turquoise: #7fe4e0;
$green: #ccdd61;
$green-active: #3da556;
$green2: #6cb9bf;

@mixin big-container {
  height: 566px;
}

@mixin small-container {
  height: 441px;
}
