@import '../../variables.scss';

.profile {
  display: flex;
  position: absolute;
  height: calc(100vh - 80px);
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  width: 100%;
  max-width: 1184px;

  .profile-image {
    width: 576px;
    height: 944px;
    min-height: 944px;
    background: url('../../assets/images/registration-image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .profile-container {
    padding: 76px 32px 0 32px;
    width: 544px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .profile-title {
      margin: 0 0 35px 0;
    }

    .profile-form {
      margin-top: 54px;
      display: flex;
      flex-direction: column;
      width: 450px;

      .profile-form-selects-wrapper {
        display: flex;
        justify-content: space-between;
      }
    }

    .profile-privacy-container {
      height: 50px;
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      .profile-privacy-link {
        color: $primary-blue;
        text-decoration: none;
      }
    }
  }
}
